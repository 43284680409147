import React from "react";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { customNumberFormat } from "src/helpers";
import { useAppSelector, useWeb3Context } from "src/hooks";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";

const claimLevelObj: any = {
  "30": 5,
  "60": 4,
  "100": 3,
  "150": 2,
  "180": 1,
};

const BurnNVBTab = ({
  nvbDays,
  handleNVBSelect,
  claimTeamReward,
  approveForBurnToken,
}: any) => {
  const { provider, address, connected, connect, chainID } = useWeb3Context();

  const burnAmt15 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt15;
  });
  const burnAmt30 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt30;
  });
  const burnAmt60 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt60;
  });
  const burnAmt100 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt100;
  });
  const burnAmt150 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt150;
  });
  const burnAmt180 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt180;
  });

  console.log("🚀 ~ burnAmt30 ~ burnAmt30:", {
    burnAmt15,
    burnAmt30,
    burnAmt60,
    burnAmt100,
    burnAmt150,
  });
  const totalClaimableReward = useAppSelector(
    (state) => state.community.totalClaimableReward
  );
  const rewardDistributorAllowance = useAppSelector((state) => {
    return state.account.rewardDistributorAllowance;
  });
  const bTokenrewardDistributorAllowance = useAppSelector((state) => {
    return state.account.bTokenrewardDistributorAllowance ?? 0;
  });
  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });
  const burnObj: any = {
    "15": burnAmt15 ?? 0,
    "30": burnAmt30 ?? 0,
    "60": burnAmt60 ?? 0,
    "100": burnAmt100 ?? 0,
    "150": burnAmt150 ?? 0,
    "180": burnAmt180 ?? 0,
  };

  return (
    <Box
      sx={{
        px: { xs: "15px", sm: "30px" },
        pb: { xs: "30px", sm: "0" },
      }}
    >
      <Box
        display="flex"
        alignItems={"center"}
        sx={{
          height: "49px",
        }}
      ></Box>
      {/* select option */}
      <Grid
        container
        rowSpacing={2.5}
        columnSpacing={2}
        sx={{
          mb: { sm: "40px", xs: "30px" },
        }}
      >
        {/* 15d */}
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              cursor: "pointer",
              border: nvbDays == 15 ? "1px solid #FCD000" : "1px solid #2d2d2d",
              p: "14px",
              borderRadius: "6px",
            }}
            onClick={() => handleNVBSelect(15)}
          >
            <Typography
              sx={{
                color: nvbDays == 15 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>15D</Trans>
            </Typography>
            <Typography
              sx={{
                color: nvbDays == 15 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>Estimated destruction</Trans>:{" "}
              {customNumberFormat(Number(burnAmt15), 4)} NVB
            </Typography>
          </Box>
        </Grid>
        {/* 30d */}
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              cursor: "pointer",
              border: nvbDays == 30 ? "1px solid #FCD000" : "1px solid #2d2d2d",
              p: "14px",
              borderRadius: "6px",
            }}
            onClick={() => handleNVBSelect(30)}
          >
            <Typography
              sx={{
                color: nvbDays == 30 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>30D</Trans>
            </Typography>
            <Typography
              sx={{
                color: nvbDays == 30 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>Estimated destruction</Trans>:{" "}
              {customNumberFormat(Number(burnAmt30), 4)} NVB
            </Typography>
          </Box>
        </Grid>
        {/* 60d */}
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              cursor: "pointer",
              border: nvbDays == 60 ? "1px solid #FCD000" : "1px solid #2d2d2d",
              p: "14px",
              borderRadius: "6px",
            }}
            onClick={() => handleNVBSelect(60)}
          >
            <Typography
              sx={{
                color: nvbDays == 60 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>60D</Trans>
            </Typography>
            <Typography
              sx={{
                color: nvbDays == 60 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>Estimated destruction</Trans>:{" "}
              {customNumberFormat(Number(burnAmt60), 4)} NVB
            </Typography>
          </Box>
        </Grid>
        {/* 100d */}
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              cursor: "pointer",
              border:
                nvbDays == 100 ? "1px solid #FCD000" : "1px solid #2d2d2d",
              p: "14px",
              borderRadius: "6px",
            }}
            onClick={() => handleNVBSelect(100)}
          >
            <Typography
              sx={{
                color: nvbDays == 100 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>100D</Trans>
            </Typography>
            <Typography
              sx={{
                color: nvbDays == 100 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>Estimated destruction</Trans>:{" "}
              {customNumberFormat(Number(burnAmt100), 4)} NVB
            </Typography>
          </Box>
        </Grid>
        {/* 150d */}
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              cursor: "pointer",
              border:
                nvbDays == 150 ? "1px solid #FCD000" : "1px solid #2d2d2d",
              p: "14px",
              borderRadius: "6px",
            }}
            onClick={() => handleNVBSelect(150)}
          >
            <Typography
              sx={{
                color: nvbDays == 150 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>150D</Trans>
            </Typography>
            <Typography
              sx={{
                color: nvbDays == 150 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>Estimated destruction</Trans>:{" "}
              {customNumberFormat(Number(burnAmt150), 4)} NVB
            </Typography>
          </Box>
        </Grid>
        {/* 180d */}
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              cursor: "pointer",
              border:
                nvbDays == 180 ? "1px solid #FCD000" : "1px solid #2d2d2d",
              p: "14px",
              borderRadius: "6px",
            }}
            onClick={() => handleNVBSelect(180)}
          >
            <Typography
              sx={{
                color: nvbDays == 180 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>180D</Trans>
            </Typography>
            <Typography
              sx={{
                color: nvbDays == 180 ? "#fcd000" : "#eaecef",
                fontSize: { sm: "16px", xs: "14px" },
              }}
            >
              <Trans>Estimated destruction</Trans>:{" "}
              {customNumberFormat(Number(burnAmt180), 4)} NVB
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          height: { sm: "65px", xs: "auto" },
          borderRadius: "6px",
          border: "1px solid #2d2d2d",
          background: "#0c0c0c",
          mb: {
            xs: "20px",
            sm: "16px",
          },
          px: { sm: "20px", xs: "15px" },
          py: { sm: "0", xs: "15px" },
        }}
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        alignItems={"center"}
        justifyContent={{
          xs: "flex-start",
          sm: "space-between",
        }}
      >
        <Box
          sx={{
            flex: { sm: "1" },
            width: "100%",
          }}
          display="flex"
          alignItems={{ sm: "center" }}
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
        >
          <Typography
            sx={{
              color: "#848e9c",
              fontSize: { xs: "16px", sm: "22px" },
              fontWeight: "400",
              mr: 0.5,
            }}
          >
            <Trans>Contribution team reward：</Trans>
          </Typography>
          {address ? (
            totalClaimableReward ? (
              <Typography
                sx={{
                  color: "#EAECEF",
                  fontSize: { xs: "16px", sm: "22px" },
                  fontWeight: "600",
                }}
              >
                {customNumberFormat(Number(totalClaimableReward), 4)} NVB
              </Typography>
            ) : (
              <Skeleton width="80px" />
            )
          ) : (
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: { xs: "16px", sm: "22px" },
                fontWeight: "600",
              }}
            >
              0 NVB
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: "200px",
            borderRadius: "6px",
            height: "44px",
            border: "1px solid #000",
            background: "#FCD000",
            boxShadow: "-3px 3px 0px 0px #ECAA00",
            mb: { xs: "10px", sm: "0" },
            display: { xs: "none", sm: "inline-block" },
            "&:hover": {
              transform: "scale(1.02)",
            },
          }}
        >
          {address ? (
            Number(bTokenrewardDistributorAllowance) >
              Number(burnObj[nvbDays]) ? (
              <LoadingButton
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                onClick={() => claimTeamReward(true)}
                variant="contained"
                // contribution_claim
                disabled={
                  isPendingTxn(pendingTransactions, "contribution_claim") ||
                  Number(totalClaimableReward) == 0
                }
                loading={isPendingTxn(
                  pendingTransactions,
                  "contribution_claim"
                )}
                loadingPosition="end"
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {txnButtonText(
                    pendingTransactions,
                    "contribution_claim",
                    t`Confirm`
                  )}
                </Typography>
              </LoadingButton>
            ) : (
              <LoadingButton
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                loadingPosition="end"
                onClick={() => approveForBurnToken("bToken")}
                disabled={isPendingTxn(
                  pendingTransactions,
                  "contribution_approve"
                )}
                loading={isPendingTxn(
                  pendingTransactions,
                  "contribution_approve"
                )}
                variant="contained"
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {txnButtonText(
                    pendingTransactions,
                    "contribution_approve",
                    t`Approve`
                  )}
                </Typography>
              </LoadingButton>
            )
          ) : (
            <Button
              sx={{
                width: "100%",
                height: "100%",
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
              onClick={connect}
              variant="contained"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <Trans>Connect Wallet</Trans>
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      {/* mobile claim button */}
      <Box
        sx={{
          width: "100%",
          borderRadius: "6px",
          height: "44px",
          border: "1px solid #000",
          background: "#FCD000",
          boxShadow: "-3px 3px 0px 0px #ECAA00",
          mb: { xs: "10px", sm: "0" },
          display: { xs: "block", sm: "none" },
          "&:hover": {
            transform: "scale(1.02)",
          },
        }}
      >
        {address ? (
          Number(bTokenrewardDistributorAllowance) >
            Number(burnObj[nvbDays]) ? (
            <LoadingButton
              sx={{
                width: "100%",
                height: "100%",
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
              onClick={() => claimTeamReward(true)}
              variant="contained"
              // contribution_claim
              disabled={
                isPendingTxn(pendingTransactions, "contribution_claim") ||
                Number(totalClaimableReward) == 0
              }
              loading={isPendingTxn(pendingTransactions, "contribution_claim")}
              loadingPosition="end"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {txnButtonText(
                  pendingTransactions,
                  "contribution_claim",
                  t`Confirm`
                )}
              </Typography>
            </LoadingButton>
          ) : (
            <LoadingButton
              sx={{
                width: "100%",
                height: "100%",
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
              loadingPosition="end"
              onClick={() => approveForBurnToken("bToken")}
              disabled={isPendingTxn(
                pendingTransactions,
                "contribution_approve"
              )}
              loading={isPendingTxn(
                pendingTransactions,
                "contribution_approve"
              )}
              variant="contained"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {txnButtonText(
                  pendingTransactions,
                  "contribution_approve",
                  t`Approve`
                )}
              </Typography>
            </LoadingButton>
          )
        ) : (
          <Button
            sx={{
              width: "100%",
              height: "100%",
              background: "transparent",
              "&:hover": {
                background: "transparent",
              },
            }}
            onClick={connect}
            variant="contained"
          >
            <Typography
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <Trans>Connect Wallet</Trans>
            </Typography>
          </Button>
        )}
      </Box>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: "#848e9c",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          <Trans>
            Tip: To claim contribution rewards, you need to burn an equivalent value of NVB
          </Trans>
        </Typography>
      </Box>
    </Box>
  );
};

export default BurnNVBTab;
