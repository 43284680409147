import { BigNumber } from "ethers";

export const THE_GRAPH_URL =
  "https://gateway.thegraph.com/api/0dfa3e82d0ecf62f09ee6e209325e521/subgraphs/id/GtpDiNYtegkqcrojnBSDM4zGgPeJoNQfwend17DCM1nb";
// "https://api.studio.thegraph.com/query/91671/metrics-test/version/latest";

// export const EPOCH_INTERVAL = 400;
export const EPOCH_INTERVAL = 9600;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 3;

export const OHM_INITIAL_PRICE = BigNumber.from("1000000000000000000");

export const TOKEN_DECIMALS = 9;

export const INVITE_PER_PAGE = 20;

interface IPoolGraphURLS {
  [index: string]: string;
}

export const DEFAULT_INVITE_ADDRESS = "";

export const POOL_GRAPH_URLS: IPoolGraphURLS = {
  4: "https://api.thegraph.com/subgraphs/name/pooltogether/rinkeby-v3_4_3",
  1: "https://api.thegraph.com/subgraphs/name/pooltogether/pooltogether-v3_4_3",
};

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const durationList: any = {
  "1": String(180 * 60), //180days
  "2": String(150 * 60), //150days
  "3": String(100 * 60), //100days
  "4": String(60 * 60), //60days
  "5": String(30 * 30), //30days
  // "1": "15552000", //180days
  // "2": "12960000", //150days
  // "3": "8640000", //100days
  // "4": "5184000", //60days
  // "5": "2592000", //30days
};

export const addresses: IAddresses = {
  97: {
    commRoot: "0xb61298a9aE2336df0528CA73c879593E5F8A2395",
    COMMUNITY_ADDRESS: "0x9BB07D8DE26A7D48C4Db15c36fC01B8E3Ea0aFc4",
    accountant: "0x057760d73035403e413259063b6EBEa6AC080142",
    pancakeFac: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
    pancakeRouter: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    marketRecipient: "0xb61298a9aE2336df0528CA73c879593E5F8A2395",
    purchaseFundRecipient: "0x057760d73035403e413259063b6EBEa6AC080142",
    USDT_ADDRESS: "0x12F62d06f2823c91deAe316F9FC9cE84a46DD616",
    bToken: "0x4f07C4164B1E82474B638c8D89bd4cAB0923720d",
    bTokenRecipient: "0x057760d73035403e413259063b6EBEa6AC080142",
    feeReceiver: "0x34061FBE3728a46dDa16B5d67F005cFba0135f26",
    OHM_ADDRESS: "0xDcd6Fb03655eDe9650B05C34e7BfEd1e7253B52c",
    mainPair: "0xB7a624C5837EE2fFC95AeF36158238048D127a31",
    BONDINGCALC_ADDRESS: "0x81b12d9fbAFa57A28146068A10E6B0aE0FAD910f",
    SOHM_ADDRESS: "0x338B7c35DE18A9b97dEF006D59A2b4fed585d84B",
    rewardDistributor: "0xCBbd72B14fAc5AF6D60C90D1ec44bC62b54CD850",
    daoRewardPool: "0xc239AFBb72E4c2eF9487154B0Ce80743C4f8cFc1",
    TREASURY_ADDRESS: "0x3D3CC3581659906944F11c3E614748cFEE7Ee77E",
    DISTRIBUTOR_ADDRESS: "0x6955267FdFEB15185f4AA327Ee6F07a737e0B711",
    STAKING_ADDRESS: "0xD86C49aa7050a3F3caF6404F2e897725255cf478",
    STAKING_WARMUP_ADDRESS: "0xb601D8834c4053b5029195CCf3EC67aA4dcf2483",
    STAKING_HELPER_ADDRESS: "0xF3b733A2C9fd0B1F343d564785F3192Ec5f20656",
    REDEEM_HELPER_ADDRESS: "0x4648b505c11C338F7E8d2c1A9b8808Ef63bBbE7D",
    DAI_BOND_ADDRESS: "0x34983153D8Bb42f3f435E03c4288B0bB0c16d3A2",
    DEPOSIT_HELPER_ADDRESS: "0x7fD6d21ca4893c6015c6283ae2030998f2936bC4",
    releasePoolHelper: "0x70AcE5aDc07c479Cc0f91CFD7F0519E68Fbb6Af3",
    BNB_ADDRESS: "0x4154a3560c8F4496B3dE5151759ede7861E321eE",
    ETH_ADDRESS: "0x9DeAf13Ce24698ff9bA96bD00FEB7C22af6bAb9d",
    BTC_ADDRESS: "0x17EAc1634D37c5a543DE0ec8d0B6c2C9e2f6E1b9",
    ETH_PRICEFEED_ADDRESS: "0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7",
    BTC_PRICEFEED_ADDRESS: "0x5741306c21795FdCBb9b265Ea0255F499DFe515C",
    stakingReleasePool: "0xD9AAE6395Bab86524DFE80aa44399E1FA1F0fd84",
    contributionReleasePool: "0x2Fa5CcEAbB372f179Bed160c3c5343E65838480E",
    USDT_STABLE_BOND_ADDRESS: "0x1844E2dEF8F1b352369D983753EB7797deB08f3E",
    airdrop: "0xddeE139d738e30c085205Af96344265e87b28161",
    WBNB_ADDRESS: "0x4f07C4164B1E82474B638c8D89bd4cAB0923720d",
    contributionReleasePoolV2: "0x49d5F11fC17a72e2ceD5720cCAFDfCF63b5b0C12",
    View: "0x3c4B1D91a94Fc3172352C5a16B4F70cA3E6004F8",
  },

  56: {
    commRoot: "0x3E4901a01c1C9625e6927872c3AD54eB0d58B6eA",
    community: "0xFf26f3D2324Dc1C8c5813feC07c1b3630f5da329",
    USDT_ADDRESS: "0x55d398326f99059ff775485246999027b3197955",
    pancakeRouter: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    pancakeFac: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    COMMUNITY_ADDRESS: "0xFf26f3D2324Dc1C8c5813feC07c1b3630f5da329",
    BNB_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    WBNB_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ETH_ADDRESS: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    BTC_ADDRESS: "0x0555e30da8f98308edb960aa94c0db47230d2b9c",
    ETH_PRICEFEED_ADDRESS: "0x9ef1B8c0E4F7dc8bF5719Ea496883DC6401d5b2e",
    BTC_PRICEFEED_ADDRESS: "0x264990fbd0A4796A3E3d8E37C4d5F87a3aCa5Ebf",
    bToken: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    marketRecipient: "0xf0CB2E8D265ff5e7A128C8f22B61Ff81559C6ada",
    purchaseFundRecipient: "0x9B49a9420Eb0440e7A1c911E894133488CF44847",
    bTokenRecipient: "0x0933D09B9106209a5e58C590aF1E4c22d2F5792f",
    accountant: "0xe0dcb9c378362902679bed1d701c482ca3d83db5",
    treasuryDaoWallet: "0xe8b1Aa6dBF9ED6Ac49286e49514852d3a00a8C4e",
    roleManager: "0xE4B25E3a628dD91Ed8bfcF09785C676CcB829430",
    feeReceiver: "0x3087e9797873200Bcd9151c7241B87cc292A7fe5",
    OHM_ADDRESS: "0x3595AfFf15A7ccaeeEb787Fd676f7A297319C24c",
    mainPair: "0x5a5fD4DBF70747E684F43B43aB53d4b0C733293D",
    BONDINGCALC_ADDRESS: "0x3dC6479A654895a71C8361dd6F88A235bf7eFc96",
    SOHM_ADDRESS: "0x6d6C16A3cbD318f3000e33cFD27662cF00F7E7c1",
    releasePoolHelper: "0xc42b129B08951531A304B94C9eda0d3C10Aa6C24",
    contributionReleasePool: "0x0FcE80b9029b95AF97C9590890ae3B1de612ac40",
    rewardDistributor: "0x596bE6AEd2Cb7D57211fA0a98Ca32cE5dC6A8B57",
    daoRewardPool: "0x17f4275D4f03Ab08a8814c05F2EE04FfDa6F93a7",
    TREASURY_ADDRESS: "0x585386a67E817849Ef5211f433432b1D28DD7Ce8",
    DISTRIBUTOR_ADDRESS: "0xAf1A5bC6857dd43BB121C76BA6FA5eB1ee683D85",
    stakingReleasePool: "0xa419A745EAfc4D8764d9324A2Ce4193Ac0C93F93",
    STAKING_ADDRESS: "0x6bCd1273eba684Ff62614e41eff87123113112D1",
    STAKING_WARMUP_ADDRESS: "0xC0b2713af9879E0d492F8BD71c61142708C3dfC2",
    STAKING_HELPER_ADDRESS: "0x190ec1f8AcF063665375235490b5915ca3fF1062",
    REDEEM_HELPER_ADDRESS: "0xe71b286a912791AA4b7fDF2cb902133381Aba96a",
    DAI_BOND_ADDRESS: "0x9F12ae8A50F7385fCE37FF962eD0d208c609cE1E",
    DAI_BOND_ADDRESS1: "0xE494d8dB48900d57DdF1C30D7f710eBDa2AC71f1",
    DEPOSIT_HELPER_ADDRESS: "0x85008E3fdeC8D145f1C01f58d50A07fffDfcfc30",
    DEPOSIT_HELPER_ADDRESS1: "0x5B14f025AfBa9c978593b4375714117B1C79770B",
    View: "0x2Ed55f1D2666EE2E80658fba4E61A6473046f261",
    contributionReleasePoolV2: "0xdf83B8cC5F70EDf17cf903bE53f2842f39ef4D72",
  },
};
