import { StableBond, LPBond, NetworkID, CustomBond, BondType } from "src/lib/Bond";
import { addresses } from "src/constants";
import BondOhmLusdHelperContractJson from "src/abi/BondHelper.json";

import { ReactComponent as BusdImg } from "src/assets/tokens/BUSD.svg";
import { ReactComponent as USDTImg } from "src/assets/tokens/USDT.svg";
import { ReactComponent as DaiImg } from "src/assets/tokens/bond_dai.svg";
import { ReactComponent as TokenDAIImg } from "src/assets/tokens/token_dai.svg";
// import { ReactComponent as TokenUSDTImg } from "src/assets/tokens/token-usdt.svg";
import { ReactComponent as FraxImg } from "src/assets/tokens/FRAX.svg";
import { ReactComponent as OhmFraxImg } from "src/assets/tokens/OHM-FRAX.svg";
import { ReactComponent as OhmLusdImg } from "src/assets/tokens/OHM-LUSD.svg";
import { ReactComponent as OhmEthImg } from "src/assets/tokens/OHM-WETH.svg";
import { ReactComponent as wETHImg } from "src/assets/tokens/wETH.svg";
import { ReactComponent as BTCBImg } from "src/assets/tokens/btcb.svg";
import { ReactComponent as LusdImg } from "src/assets/tokens/LUSD.svg";
import { ReactComponent as CvxImg } from "src/assets/tokens/CVX.svg";
import FraxOhmBondContractJson from "src/abi/bonds/OhmFraxContract.json";
import BondOhmLusdContractJson from "src/abi/bonds/OhmLusdContract.json";
import BondOhmEthContractJson from "src/abi/bonds/OhmEthContract.json";

import DaiBondContractJson from "src/abi/bonds/DaiContract.json";
import ReserveOhmLusdContractJson from "src/abi/reserves/OhmLusd.json";
import ReserveOhmDaiContractJson from "src/abi/reserves/OhmDai.json";
import ReserveOhmFraxContractJson from "src/abi/reserves/OhmFrax.json";
import ReserveOhmEthContractJson from "src/abi/reserves/OhmEth.json";

import FraxBondContractJson from "src/abi/bonds/FraxContract.json";
import LusdBondContractJson from "src/abi/bonds/LusdContract.json";
import CvxBondContractJson from "src/abi/bonds/CvxContract.json";
import BondOhmDaiContractJson from "src/abi/bonds/OhmDaiContract.json";
import ierc20AbiJson from "src/abi/IERC20.json";
import { getBondCalculator } from "src/helpers/BondCalculator";
import { BigNumberish } from "ethers";
import { getTokenPrice } from "src/helpers";

const BondOhmLusdContract = BondOhmLusdContractJson.abi;
const BondOhmEthContract = BondOhmEthContractJson.abi;
const FraxBondContract = FraxBondContractJson.abi;
const LusdBondContract = LusdBondContractJson.abi;
const CvxBondContract = CvxBondContractJson.abi;
const FraxOhmBondContract = FraxOhmBondContractJson.abi;
const DaiBondContract = DaiBondContractJson.abi;
const BondOhmLusdHelperContract = BondOhmLusdHelperContractJson.abi;
const ReserveOhmDaiContract = ReserveOhmDaiContractJson.abi;

const BondOhmDaiContract = BondOhmDaiContractJson.abi;
const ierc20Abi = ierc20AbiJson.abi;

export const usdt = new StableBond({
  name: "usdt",
  displayName: "USDT",
  bondToken: "USDT",
  isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: false },
  bondIconSvg: USDTImg,
  bondContractABI: BondOhmDaiContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x91D743F68142622e29abEC08F07ea34F0b8197B0",
      reserveAddress: "0x55d398326f99059ff775485246999027b3197955",
      bondHelperAddress: "0x91D743F68142622e29abEC08F07ea34F0b8197B0",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x3074EB3afEA54bCDbDd4191dE351488aFCa71705",
      reserveAddress: "0x12F62d06f2823c91deAe316F9FC9cE84a46DD616",
      bondHelperAddress: "0x3074EB3afEA54bCDbDd4191dE351488aFCa71705",
    },
  },
});

export const ohm_dai = new LPBond({
  name: "nvb-usdt_lp1",
  displayName: "NVB-USDT LP",
  bondToken: "USDT",
  isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: false },
  bondIconSvg: TokenDAIImg,
  bondContractABI: BondOhmDaiContract,
  reserveContract: ReserveOhmDaiContract,
  bondHelperContractABI: BondOhmLusdHelperContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x9F12ae8A50F7385fCE37FF962eD0d208c609cE1E",
      reserveAddress: "0x5a5fD4DBF70747E684F43B43aB53d4b0C733293D",
      bondHelperAddress: "0x85008E3fdeC8D145f1C01f58d50A07fffDfcfc30",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x34983153D8Bb42f3f435E03c4288B0bB0c16d3A2",
      reserveAddress: "0xB7a624C5837EE2fFC95AeF36158238048D127a31",
      bondHelperAddress: "0x7fD6d21ca4893c6015c6283ae2030998f2936bC4",
    },
  },
  lpUrl: "",
});

export const ohm_dai1 = new LPBond({
  name: "nvb-usdt_lp",
  displayName: "NVB-USDT LP",
  bondToken: "USDT",
  isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: false },
  bondIconSvg: TokenDAIImg,
  bondContractABI: BondOhmDaiContract,
  reserveContract: ReserveOhmDaiContract,
  bondHelperContractABI: BondOhmLusdHelperContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xE494d8dB48900d57DdF1C30D7f710eBDa2AC71f1",
      reserveAddress: "0x5a5fD4DBF70747E684F43B43aB53d4b0C733293D",
      bondHelperAddress: "0x5B14f025AfBa9c978593b4375714117B1C79770B",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x34983153D8Bb42f3f435E03c4288B0bB0c16d3A2",
      reserveAddress: "0xB7a624C5837EE2fFC95AeF36158238048D127a31",
      bondHelperAddress: "0x7fD6d21ca4893c6015c6283ae2030998f2936bC4",
    },
  },
  lpUrl: "",
});

// HOW TO ADD A NEW BOND:
// Is it a stableCoin bond? use `new StableBond`
// Is it an LP Bond? use `new LPBond`
// Add new bonds to this array!!

export const allBonds = [ohm_dai1, ohm_dai, usdt];
// export const allBonds = [usdt];
// TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
export const allExpiredBonds = [];
// export const allExpiredBonds = [cvx_expired];
export const allBondsMap = allBonds.reduce((prevVal, bond) => {
  return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
export default allBonds;
