import React, { useEffect } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { useAppSelector, useWeb3Context } from "src/hooks";
import dayjs from "dayjs";
import { CustormPagination } from "src/components";
import { useMobile } from "../../hooks";
import { INVITE_PER_PAGE } from "src/constants";
import { getFlowHistory } from "src/slices/CommunitySlice";
import { useDispatch } from "react-redux";
import { customNumberFormat, trim } from "src/helpers";
import NoData from "src/assets/images/noData.svg";

const FlowData = () => {
  const { isSmallScreen } = useMobile();
  const dispatch = useDispatch();
  const { address, connected } = useWeb3Context();

  const rewardHistoryList = useAppSelector((state) => {
    return state.community.rewardHistoryList;
  });
  const rewardHistoryTotal = useAppSelector((state) => {
    return state.community.rewardHistoryTotal;
  });

  const flowDataChange = (e: any, page: any) => {
    dispatch(getFlowHistory({ page, address }));
  };

  useEffect(() => {
    if (address) {
      dispatch(getFlowHistory({ page: 1, address }));
    }
  }, [address, connected])

  const count = Math.ceil((rewardHistoryTotal || 0) / INVITE_PER_PAGE);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "300px",
          borderRadius: "6px",
          border: "1px solid #2d2d2d",
          background: "#151515",
          px: { xs: "15px", sm: "0" },
          py: { xs: "20px", sm: "0" },
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    color: "#848E9C",
                    fontSize: { xs: "12px", sm: "16px" },
                    fontWeight: "400",
                    borderBottom: "none",
                    p: {
                      xs: "0",
                      sm: "16px",
                    },
                  }}
                >
                  <Trans>Time</Trans>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#848E9C",
                    fontSize: { xs: "12px", sm: "16px" },
                    fontWeight: "400",
                    borderBottom: "none",
                    p: {
                      xs: "0",
                      sm: "16px",
                    },
                  }}
                >
                  <Trans>Type</Trans>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#848E9C",
                    fontSize: { xs: "12px", sm: "16px" },
                    fontWeight: "400",
                    borderBottom: "none",
                    p: {
                      xs: "0",
                      sm: "16px",
                    },
                  }}
                >
                  <Trans>Amount NVB</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rewardHistoryList && rewardHistoryList.length > 0 ? (
                rewardHistoryList.map((item, index) => (
                  <TableRow key={`${item.transactionHash}-${index}`}>
                    <TableCell
                      align="left"
                      sx={{
                        borderBottom: "none",
                        p: {
                          xs: "0",
                          sm: "16px",
                        },
                        height: "40px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#eaecef",
                          fontSize: { xs: "12px", sm: "16px" },
                          fontWeight: "500",
                          borderBottom: "none",
                        }}
                      >
                        {dayjs(item.blockTimestamp).format("YYYY/MM/DD")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "none",
                        height: "40px",

                        p: {
                          xs: "0",
                          sm: "16px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#eaecef",
                          fontSize: { xs: "12px", sm: "16px" },
                          fontWeight: "500",
                          borderBottom: "none",
                        }}
                      >
                        {/* {typeList[item.rewardType]} */}
                        {item.rewardType == 0
                          ? t`Contribution Weight Reward`
                          : item.rewardType == 1
                            ? t`Daily new rewards`
                            : item.rewardType == 2
                              ? t`Ranking Rewards`
                              : t`Bond Direct Referral Rewards`}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        height: "40px",
                        borderBottom: "none",
                        p: {
                          xs: "0",
                          sm: "16px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#eaecef",
                          fontSize: { xs: "12px", sm: "16px" },
                          fontWeight: "500",
                          borderBottom: "none",
                        }}
                      >
                        {item.amount
                          ? customNumberFormat(Number(item.amount), 4)
                          : "0"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    sx={{
                      height: "300px",
                      borderBottom: "none",
                    }}
                  >
                    <Box
                      sx={{ width: "100%", height: "100%" }}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <img src={NoData} alt="No Data" />
                      <Typography
                        sx={{
                          pt: 2,
                          color: "#848E9C",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>No data</Trans>
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rewardHistoryTotal && rewardHistoryTotal > INVITE_PER_PAGE && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              mb: 2,
            }}
          >
            <CustormPagination
              siblingCount={isSmallScreen ? 0 : 1}
              boundaryCount={1}
              count={count}
              onChange={flowDataChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FlowData;
