import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
  Tabs,
  Tab,
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { BondDataCard, BondTableData } from "./BondRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatCurrency } from "../../helpers";
import useBonds from "../../hooks/Bonds";
import { useWeb3Context } from "src/hooks/web3Context";
import { useEffect, useState } from "react";
import "./choosebond.scss";
import { Skeleton } from "@mui/material";
import { ClaimBonds, ClaimDirectBonds } from "./ClaimBonds";
import { allBondsMap } from "src/helpers/AllBonds";
import { useAppSelector } from "src/hooks";
import BondTitle from "src/assets/images/bond-title.png";
import {
  calculateUserBondDetails,
  calculateUserDirectBondDetails,
  IUserBondDetails,
} from "src/slices/AccountSlice";
import { useMobile } from "../../hooks";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";
import NoData from "src/assets/images/noData.svg";
import { ClaimBondTableData, ClaimBondCardData } from "./ClaimRow";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as BondIcon } from "../../assets/icons/bond.svg";
import FooterBg from "../../assets/images/footer-bg.png";

import {
  txnButtonText,
  isPendingTxn,
  txnButtonTextGeneralPending,
} from "src/slices/PendingTxnsSlice";
import { useDispatch } from "react-redux";

function ChooseBond() {
  const dispatch = useDispatch();
  const { provider, address, chainID, connected } = useWeb3Context();

  const { isSmallScreen } = useMobile();
  const [tab, setTab] = useState(0);
  let { bonds } = useBonds(chainID);
  bonds = bonds.filter(
    (item: any) => item.name != "nvb-usdt_lp1"
  );
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDirectBondPage, setCurrentDirectBondPage] = useState(1);
  const isAppLoading: boolean = useAppSelector((state) => state.app.loading);
  const isAccountLoading: boolean = useAppSelector(
    (state) => state.account.loading
  );
  const accountBonds: IUserBondDetails[] | any[] = useAppSelector((state) => {
    let withInterestDue: any[] = [];
    for (const bond in state.account.bonds) {
      if (Array.isArray(state.account.bonds[bond])) {
        let _bonds = state.account.bonds[bond];
        _bonds = (_bonds as any).filter(
          (_bond: { id: any; interestDue: any }) =>
            _bond.id && Number(_bond.id) >= 0 && Number(_bond.interestDue) > 0
        );
        withInterestDue = [
          ...withInterestDue,
          ...((_bonds as unknown) as any[]),
        ];
      } else if (
        Number((state.account.bonds[bond] as IUserBondDetails).interestDue) > 0
      ) {
        withInterestDue.push(state.account.bonds[bond]);
      }
    }
    withInterestDue = withInterestDue.sort((a, b) => {
      return b.pendingPayout - a.pendingPayout;
    });
    return withInterestDue;
  });

  const accountDirectBonds: IUserBondDetails[] | any[] = useAppSelector(
    (state) => {
      let withInterestDue: any[] = [];
      for (const bond in state.account.directBonds) {
        if (Array.isArray(state.account.directBonds[bond])) {
          let _bonds = state.account.directBonds[bond];
          _bonds = (_bonds as any).filter(
            (_bond: { interestDue: any }) =>
              _bond.interestDue && Number(_bond.interestDue) > 0
          );
          // console.log("state.account.bonds _bonds:", _bonds);
          withInterestDue = [
            ...withInterestDue,
            ...((_bonds as unknown) as any[]),
          ];
        } else if (
          (state.account.directBonds[bond] as IUserBondDetails).interestDue > 0
        ) {
          withInterestDue.push(state.account.directBonds[bond]);
        }
      }

      withInterestDue = withInterestDue.sort((a, b) => {
        return b.pendingPayout - a.pendingPayout;
      });
      return withInterestDue;
    }
  );

  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
      isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
    ) {
      return true;
    }

    return false;
  };

  const marketPrice: number | undefined = useAppSelector((state) => {
    return state.app.marketPrice;
  });

  const treasuryBalance: number | undefined = useAppSelector((state) => {
    // if (state.bonding.loading == false) {
    let tokenBalances = 0;
    for (const bond in allBondsMap) {
      if (state.bonding[bond] && state.bonding[bond].bond !== 'nvb-usdt_lp1') {
        tokenBalances += state.bonding[bond].purchased;
      }
    }
    return tokenBalances;
    // }
  });

  const changePage = (event: any, page: any) => {
    setCurrentPage(page);
  };

  const directBondChangePage = (event: any, page: any) => {
    setCurrentDirectBondPage(page);
  };

  const changeTab = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  useEffect(() => {
    if (tab !== 0 && connected && address) {
      bonds.map((bond) => {
        // console.log("bond", bond);
        dispatch(
          calculateUserBondDetails({
            address,
            bond,
            provider,
            networkID: chainID,
          })
        );
        dispatch(
          calculateUserDirectBondDetails({
            address,
            bond,
            provider,
            networkID: chainID,
          })
        );
      });
    }
  }, [tab]);

  console.log("[accountBonds]", { accountBonds });
  const currentPageBonds = accountBonds.slice(
    (currentPage - 1) * 5,
    currentPage * 5
  );
  const pageCount = Math.ceil(accountBonds.length / 5);
  const currentPageDirectBonds = accountDirectBonds.slice(
    (currentPage - 1) * 5,
    currentPage * 5
  );
  const directBondsPageCount = Math.ceil(accountDirectBonds.length / 5);
  console.log("treast", { treasuryBalance, marketPrice });
  return (
    <Box
      display="flex"
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        mb: "100px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          maxWidth: "1340px",
          width: { xs: "100%", sm: "97%" },
          pt: { sm: "87px", xs: "18px" },
          px: { xs: "15px", sm: "0" },
          position: "relative",
          zIndex: "1",
        }}
      >
        {/* bond top */}
        <Box
          sx={{
            mb: {
              xs: "20px",
              sm: "50px",
            },
          }}
          display="flex"
          alignItems={"cneter"}
        >
          <Box
            display="flex"
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
          >
            <Box
              sx={{
                mr: { sm: "20px" },
              }}
            >
              <StarIcon />
            </Box>
            <Box display="flex" alignItems={"center"}>
              <BondIcon />
              {/* <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "35.192px",
                  fontWeight: "400",
                  textTransform: "uppercase",
                }}
              >
                <Trans>Bond</Trans>
              </Typography> */}
            </Box>
          </Box>
          {/* mobile tile */}
          <Box
            sx={{
              width: "100%",
              "&>img": {
                width: "30%",
              },
              display: {
                xs: "block",
                sm: "none",
              },
            }}
          >
            <img src={BondTitle} alt="bonds" />
          </Box>
        </Box>
        {/* bond tabs */}
        <Box
          sx={{
            mb: { sm: "50px", xs: "15px" },
          }}
        >
          <Tabs
            value={tab}
            onChange={changeTab}
            sx={{
              borderBottom: "1px solid #2D2D2D",
              "& .MuiTabs-indicator": {
                background: "#fff",
              },
            }}
          >
            <Tab
              label={t({
                id: "do_bond",
              })}
              sx={{
                color: "#848E9C",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "#fff",
                  fontWeight: "600",
                },
              }}
            />
            <Tab
              label={t`Your Bond`}
              sx={{
                color: "#848E9C",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "#fff",
                  fontWeight: "600",
                },
              }}
            />
            <Tab
              label={t`Direct Bond Award`}
              sx={{
                color: "#848E9C",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "#fff",
                  fontWeight: "600",
                },
              }}
            />
          </Tabs>
        </Box>
        {/* tab content */}
        <Box
          sx={{
            borderRadius: { xs: "6px", sm: "10px" },
            border: "1px solid #2d2d2d",
            background: "#151515",
            minHeight: "400px",
            pb: "20px",
          }}
        >
          {tab == 0 ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
              }}
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid
                container
                item
                xs={12}
                sx={{ my: { sm: "60x", xs: "30px" } }}
                className="bond-hero"
              >
                <Grid item xs={12} sm={6}>
                  <Box
                    textAlign={`${isVerySmallScreen ? "left" : "center"}`}
                    sx={{
                      mb: isSmallScreen ? 2 : 0,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#848E9C",
                        fontSize: { xs: "14px", sm: "20px" },
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      align="center"
                    >
                      <Trans>Treasury Balance</Trans>
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          color: "#eaecef",
                          fontSize: { xs: "20px", sm: "28px" },
                          fontWeight: "600",
                        }}
                        data-testid="treasury-balance"
                      >
                        {!treasuryBalance ? (
                          <Skeleton
                            width="180px"
                            data-testid="treasury-balance-loading"
                          />
                        ) : treasuryBalance == 0 ? (
                          "--"
                        ) : (
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          }).format(Number(treasuryBalance))
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} className={`ohm-price`}>
                  <Box textAlign={`${isVerySmallScreen ? "right" : "center"}`}>
                    <Typography
                      sx={{
                        color: "#848E9C",
                        fontSize: { xs: "14px", sm: "20px" },
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      align="center"
                    >
                      <Trans>OHM Price</Trans>
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          color: "#EAECEF",
                          fontSize: { xs: "20px", sm: "28px" },
                          fontWeight: "600",
                        }}
                      >
                        {!marketPrice ? (
                          <Skeleton width="100px" />
                        ) : marketPrice == 0 ? (
                          "--"
                        ) : (
                          formatCurrency(Number(marketPrice), 4)
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {!isSmallScreen ? (
                <Grid container item>
                  <TableContainer>
                    <Table aria-label="Available bonds">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderBottom: "none",
                            }}
                          />
                          <TableCell
                            align="left"
                            sx={{
                              color: "#848E9C",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>Bond</Trans>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: "#848E9C",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>Price</Trans>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: "#848E9C",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>ROI</Trans>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              color: "#848E9C",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>Bought</Trans>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderBottom: "none" }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bonds.map((bond) => (
                          <BondTableData key={bond.name} bond={bond} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    px: { xs: "15px", sm: "0" },
                  }}
                >
                  <Grid container item spacing={2}>
                    {bonds.map((bond, index) => (
                      <Grid item xs={12} key={bond.name}>
                        <BondDataCard key={bond.name} bond={bond} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Box>
          ) : tab == 1 ? (
            <Box
              sx={{
                px: { xs: "15px", sm: "20px" },
                height: "100%",
              }}
            >
              <ClaimBonds
                count={pageCount}
                activeBonds={currentPageBonds}
                changePage={changePage}
              />
              {/* {isSmallScreen ? (
                Object.entries(currentPageBonds).length > 0 ? (
                  <Box>

                 { Object.entries(currentPageBonds).map((bond, i) => (
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <ClaimBondCardData key={i} userBond={bond} />
                    </Box>

                  ))}

                  </Box>

                ) : (
                  <Box
                    sx={{ width: "100%", height: "330px" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <img src={NoData} alt="No Data" />
                    <Typography
                      sx={{
                        pt: 2,
                        color: "#C7C8CC",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>No data</Trans>
                    </Typography>
                  </Box>
                )
              ) : (
                <ClaimBonds
                  count={pageCount}
                  activeBonds={currentPageBonds}
                  changePage={changePage}
                />
              )} */}
            </Box>
          ) : (
            <Box
              sx={{
                px: { xs: "15px", sm: "20px" },
                height: "100%",
                minHeight: "350px",
              }}
            >
              {/* {isSmallScreen ? (
                Object.entries(currentDirectBondPage).length > 0 ? (
                  <Box>
                    {Object.entries(currentDirectBondPage).map((bond, i) => (
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <ClaimBondCardData key={i} userBond={bond} />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box
                    sx={{ width: "100%", height: "274px" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <img src={NoData} alt="No Data" />
                    <Typography
                      sx={{
                        pt: 2,
                        color: "#C7C8CC",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>No data</Trans>
                    </Typography>
                  </Box>
                )
              ) : ( */}
              <ClaimDirectBonds
                directBondChangePage={directBondChangePage}
                directBondCount={currentDirectBondPage}
                directBonds={accountDirectBonds}
              />
              {/* )} */}
            </Box>
          )}
        </Box>
      </Box>
      {/* left top bg */}
      <Box
        sx={{
          position: "absolute",
          left: "-20px",
          top: { xs: "-50px", sm: "-25px" },
          width: { xs: "186px", sm: "390px" },
          height: { xs: "309px", sm: "509px" },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={FooterBg} />
      </Box>
    </Box>
  );
}

export default ChooseBond;
