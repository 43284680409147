import BondLogo from "../../components/BondLogo";
import { DisplayBondPrice, DisplayBondDiscount } from "../Bond/Bond.jsx";
import {
  Box,
  Button,
  Link,
  Paper,
  Typography,
  TableRow,
  TableCell,
  SvgIcon,
  Slide,
} from "@mui/material";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { NavLink } from "react-router-dom";
import "./choosebond.scss";
import { t, Trans } from "@lingui/macro";
import { Skeleton } from "@mui/material";
import useBonds from "src/hooks/Bonds";
import { useWeb3Context } from "../../hooks/web3Context";
import { useMobile } from "src/hooks";
export function BondDataCard({ bond }) {
  const { isSmallScreen } = useMobile();
  const { chainID } = useWeb3Context();
  const { loading } = useBonds(chainID);
  const isBondLoading = !bond.bondPrice ?? true;
  return (
    <Slide direction="up" in={true}>
      <Paper
        id={`${bond.name}--bond`}
        className="bond-data-card ohm-card"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          mb: "30px !important",
        }}
      >
        <Box
          sx={{
            mb: isSmallScreen ? "20px !important" : "15px !important",
          }}
          className="bond-pair"
          display="flex"
          alignItems={"center"}
        >
          <BondLogo bond={bond} />
          <Box
            sx={{
              ml: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {bond.displayName}
            </Typography>
          </Box>
        </Box>
        <div className="data-row">
          <Typography
            sx={{
              color: "#848E9C",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            <Trans>Price</Trans>
          </Typography>
          <Typography
            className="bond-price"
            sx={{
              color: "#EAECEF",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            <>
              {isBondLoading ? (
                <Skeleton width="50px" />
              ) : (
                <DisplayBondPrice key={bond.name} bond={bond} />
              )}
            </>
          </Typography>
        </div>
        <div className="data-row">
          <Typography
            sx={{
              color: "#848E9C",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            <Trans>ROI</Trans>
          </Typography>
          <Typography
            sx={{
              color: "#EAECEF",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {isBondLoading ? (
              <Skeleton width="50px" />
            ) : (
              <DisplayBondDiscount key={bond.name} bond={bond} />
            )}
          </Typography>
        </div>

        <Box
          sx={{
            mb: isSmallScreen ? "20px !important" : 1.375,
          }}
          className="data-row"
        >
          <Typography
            sx={{
              color: "#848E9C",
              fontSize: "12px",
              fontWeight: "700",
            }}
          >
            <Trans>Bought</Trans>
          </Typography>
          <Typography
            sx={{
              color: "#EAECEF",
              fontSize: "1p4x",
              fontWeight: "500",
            }}
          >
            {isBondLoading ? (
              <Skeleton width="50px" />
            ) : (
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(bond.purchased)
            )}
          </Typography>
        </Box>
        <Link
          // component={"div"}
          component={NavLink}
          to={`/bonds/${bond.name}`}
        >
          <Box
            sx={{
              borderRadius: "6px",
              border: "1px solid #000",
              background: "#FCD000",
              boxShadow: "-3px 3px 0px 0px #ECAA00",
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          >
            <Button
              sx={{
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
              variant="contained"
              fullWidth
              disabled={!bond.isAvailable[chainID]}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {!bond.isAvailable[chainID]
                  ? t`Sold Out`
                  : t`Bond ${bond.displayName}`}
              </Typography>
            </Button>
          </Box>
        </Link>
      </Paper>
    </Slide>
  );
}

export function BondTableData({ bond }) {
  const { chainID } = useWeb3Context();
  // Use BondPrice as indicator of loading.
  const isBondLoading = !bond.bondPrice ?? true;
  // const isBondLoading = useSelector(state => !state.bonding[bond]?.bondPrice ?? true);
  console.log("isBondLoading", bond.name, bond.bondPrice, bond);

  return (
    <TableRow id={`${bond.name}--bond`}>
      <TableCell
        align="center"
        className="bond-name-cell"
        sx={{ borderBottom: "none" }}
      >
        <Box display="flex" alignItems={"center"} justifyContent={"flex-end"}>
          <BondLogo bond={bond} />
        </Box>
      </TableCell>
      <TableCell
        align="left"
        className="bond-name-cell"
        sx={{ borderBottom: "none" }}
      >
        <Box className="bond-name">
          <Typography
            sx={{
              color: "#EAECEF",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {bond.displayName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="left" sx={{ borderBottom: "none" }}>
        <Typography
          sx={{
            color: "#EAECEF",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          <>
            {isBondLoading ? (
              <Skeleton width="50px" />
            ) : (
              <DisplayBondPrice key={bond.name} bond={bond} />
            )}
          </>
        </Typography>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          borderBottom: "none",
          color: "#EAECEF",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        {isBondLoading ? (
          <Skeleton width="50px" />
        ) : (
          <DisplayBondDiscount key={bond.name} bond={bond} />
        )}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          borderBottom: "none",
          color: "#EAECEF",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        <Box display="flex" alignItems={"center"} justifyContent={"flex-end"}>
          {isBondLoading ? (
            <Skeleton width="50px" />
          ) : (
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(bond.purchased)
          )}
        </Box>
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "none",
        }}
      >
        <Link
          // component={'div'}
          component={NavLink}
          to={`/bonds/${bond.name}`}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              height: "35px",
              borderRadius: "6px",
              border: "1px solid #000",
              background: "#fcd000",
              boxShadow: "-3px 3px 0px 0px #ECAA00",
              width: {
                xs: "100%",
                // sm: "85px",
                sm: !bond.isAvailable[chainID] ? "120px" : "85px",
              },
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          >
            <Button
              sx={{
                width: "100%",
                height: "100%",
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
              variant="contained"
              disabled={!bond.isAvailable[chainID]}
            >
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  textTransform: "capitalize",
                }}
              >
                {!bond.isAvailable[chainID] ? t`Sold Out` : t`do_bond`}
                {/* {!bond.isAvailable[chainID] ? "Coming soon" : t`do_bond`} */}
              </Typography>
            </Button>
          </Box>
        </Link>
      </TableCell>
    </TableRow>
  );
}
